var tableColumns = [{
  title: "申请ID",
  dataIndex: "id",
  key: "id",
  width: 140
}, {
  title: "申请提现金额",
  dataIndex: "amount",
  key: "amount",
  width: 120
}, {
  title: "提现币种",
  dataIndex: "tokentype",
  key: "tokentype",
  width: 100,
  scopedSlots: {
    customRender: "tokentype"
  }
}, {
  title: "网络",
  dataIndex: "networktype",
  key: "networktype",
  width: 100,
  scopedSlots: {
    customRender: "networktype"
  }
}, {
  title: "用户昵称",
  dataIndex: "username",
  key: "username",
  width: 180
  // sorter: true,
  // sortOrder: "descend",
}, {
  title: "申请时间",
  dataIndex: "createdtime",
  key: "createdtime",
  width: 180,
  sorter: true,
  sortOrder: "ascend"
}, {
  title: "打款金额",
  dataIndex: "paymentamount",
  key: "paymentamount",
  width: 120,
  scopedSlots: {
    customRender: "paymentamount"
  }
}, {
  title: "预估Network Fee(USDT)",
  dataIndex: "networkfee",
  key: "networkfee",
  width: 110,
  scopedSlots: {
    customRender: "networkfee"
  }
}, {
  title: "预估到账金额",
  dataIndex: "estimateamount",
  key: "estimateamount",
  width: 110,
  scopedSlots: {
    customRender: "estimateamount"
  }
}, {
  title: "实际Network Fee(ETH)",
  dataIndex: "actualnetworkfee",
  key: "actualnetworkfee",
  width: 110,
  scopedSlots: {
    customRender: "actualnetworkfee"
  }
}, {
  title: "实际到账金额",
  dataIndex: "actualamount",
  key: "actualamount",
  width: 110,
  scopedSlots: {
    customRender: "actualamount"
  }
}, {
  title: "钱包地址",
  dataIndex: "walletaddress",
  key: "walletaddress",
  width: 180
}, {
  title: "Tx Hash",
  dataIndex: "txhash",
  key: "txhash",
  width: 200,
  scopedSlots: {
    customRender: "txhash"
  }
}, {
  title: "状态",
  dataIndex: "status",
  key: "status",
  width: 80,
  scopedSlots: {
    customRender: "status"
  }
}, {
  title: "操作",
  key: "action",
  dataIndex: "action",
  fixed: "right",
  width: 140,
  scopedSlots: {
    customRender: "action"
  }
}];
export { tableColumns };