import { POST, GET } from "@/utils/util";
var WithdrawApi = {
  // 提现记录列表
  getWithdrawList: function getWithdrawList() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST("/api/communityservice/token/withdraw/logs", params);
  },
  // 提现数值配置
  getWithdrawConfig: function getWithdrawConfig() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST("/api/communityservice/token/withdraw/config", params);
  },
  // 创建或修改提现数值配置
  updateWithdrawConfig: function updateWithdrawConfig() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST("/api/communityservice/token/withdraw/config/update", params);
  },
  // 导入提现记录
  importWithdrawLog: function importWithdrawLog() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST("/api/communityservice/token/withdraw/logs/import", params);
  },
  // 导出提现记录
  exportWithdrawLog: function exportWithdrawLog() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return POST("/api/communityservice/token/withdraw/logs/export", params);
  },
  // 获取用户信息
  getUserInfoByName: function getUserInfoByName() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return GET("/api/accountservice/user/open/search/username/v2", params);
  },
  // 修改提现记录
  updateWithdrawLog: function updateWithdrawLog(params) {
    var id = params.id;
    delete params.id;
    return POST("/api/communityservice/token/withdraw/".concat(id, "/update"), params);
  },
  updateWithdrawStatus: function updateWithdrawStatus(params) {
    var id = params.id;
    delete params.id;
    return POST("/api/communityservice/token/withdraw/".concat(id, "/update/status"), params);
  }
};
export default WithdrawApi;